<template>
  <div>
    <QuizStatistics />
  </div>
</template>

<script>
import QuizStatistics from "../components/ReportCenter/QuizStatistics";
export default {
  components: {
    QuizStatistics,
  },
};
</script>

<style></style>
